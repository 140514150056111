



















import { Component, Vue } from 'vue-property-decorator';
import firebase from 'firebase/app';
import 'firebase/firestore';

@Component
export default class Home extends Vue {
  private numbers = [] as any[];
  private number = -1;
  private numberExists = false;
  async mounted(){
    if(localStorage.number) {
      this.number = localStorage.number;
      this.numberExists = true;
      return;
    }
    const docs = await firebase.firestore().collection("uniqueNumbers").where("used", "==", false).limit(1).get();
    if(docs.size == 0){
      this.number = 0;
      return;
    }
    docs.forEach((doc)=>{
        this.numbers.push(doc);
    });
    const picked = this.numbers[0];
    this.number = picked.data().number;
    localStorage.number = this.number;
    await firebase.firestore().collection("uniqueNumbers").doc(picked.id).update({used: true});
  }
}
