import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

Vue.config.productionTip = false

import firebase from 'firebase'
const firebaseConfig = {
  apiKey: "AIzaSyDsLDk3YUsliDVYi3okIJhkYrrqWwA93HE",
  authDomain: "uniquenumber-423a6.firebaseapp.com",
  projectId: "uniquenumber-423a6",
  storageBucket: "uniquenumber-423a6.appspot.com",
  messagingSenderId: "704114332257",
  appId: "1:704114332257:web:6bcd001cd0810d74929020"
};

firebase.initializeApp(firebaseConfig);


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
